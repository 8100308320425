import React, { useState, useRef } from "react";
import Layout from "../components/Layout";
import Paper from "../components/Paper";

const Assets = () => {
  const sign = useRef(null);
  const [name, setName] = useState("Your name");
  const [role, setRole] = useState("Position");
  const [email, setEmail] = useState("name");
  const [mobile, setMobile] = useState("+971 58 500 00 00");

  const handleChange = (updater) => (e) => updater(e.target.value);
  const openSignature = (e) => {
    e.preventDefault();

    const windowObjectReference = window.open(
      "about:blank",
      "signature",
      "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=800,height=480"
    );

    windowObjectReference.document.body.innerHTML = sign.current.innerHTML;
    const pic = windowObjectReference.document.getElementsByTagName("img")[0];
    pic.src = window.location.origin + pic.getAttribute("src");
  };

  return (
    <Layout fullgreen title="About">
      <Paper>
        <div style={{ paddingBottom: "20px" }}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Full Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Your name"
              value={name}
              onChange={handleChange(setName)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="role" className="form-label">
              Position (i.e. Sales Manager):
            </label>
            <input
              type="text"
              className="form-control"
              id="role"
              placeholder="Sales Manager"
              value={role}
              onChange={handleChange(setRole)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email (without @supplyme.ae):
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              placeholder="email"
              value={email}
              onChange={handleChange(setEmail)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Mobile (with +971):
            </label>
            <input
              type="text"
              className="form-control"
              id="phone"
              placeholder="+971 58 500 00 00"
              value={mobile}
              onChange={handleChange(setMobile)}
            />
          </div>
        </div>
        <div ref={sign}>
          <table
            cellSpacing="0"
            cellPadding="0"
            style={{
              marginTop: "20px",
              width: "360px",
              fontSize: "12px",
              fontFamily: "Helvetica, sans-serif",
              lineHiehgt: "normal",
            }}
          >
            <tbody>
              <tr>
                <td
                  valign="top"
                  style={{ paddingBottom: "12px", display: "flex" }}
                >
                  <img
                    alt="SupplyMe"
                    src={"/signature-logo.png"}
                    width="46"
                    border="0"
                    style={{
                      width: "110px",
                      height: "46px",
                      border: "0px none",
                      marginLeft: "auto",
                      marginRight: "20px",
                    }}
                  />
                </td>
                <td
                  style={{
                    paddingBottom: "6px",
                    width: "230px",
                    verticalAlign: "top",
                  }}
                >
                  <table cellSpacing="0" cellPadding="0" width="100%">
                    <tbody>
                      <tr>
                        <td style={{ paddingBottom: "6px" }}>
                          <span
                            style={{
                              fontFamily: "Helvetica, sans-serif",
                              fontWeight: "bold",
                              color: "rgb(61, 60, 63)",
                              fontSize: "14px",
                            }}
                          >
                            {name}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            paddingBottom: "10px",
                            fontFamily: "Helvetica, sans-serif",
                            color: "rgb(61, 60, 63)",
                            borderBottom: "1px solid rgb(230, 230, 230)",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "13px",
                              color: "rgb(126, 126, 126)",
                              textTransform: "capitalize",
                            }}
                          >
                            {role}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ paddingTop: "10px", paddingBottom: "8px" }}
                        >
                          <a
                            href={`mailto:${email.toLowerCase()}@supplyme.ae`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "rgb(18, 95, 82)",
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "13px",
                              textDecoration: "none",
                            }}
                          >
                            {`${email.toLowerCase()}@supplyme.ae`}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "13px",
                          }}
                        >
                          <a
                            href={`tel:${mobile.replace(/\s/, "")}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "rgb(126, 126, 126)",
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "13px",
                              lineHeight: "18px",
                              textDecoration: "none",
                            }}
                          >
                            {mobile}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            color: "rgb(126, 126, 126)",
                            fontFamily: "Helvetica, sans-serif",
                            fontSize: "13px",
                            lineHeight: "18px",
                          }}
                        >
                          Astrolabs, Jumeirah Lakes Towers, Dubai, United Arab
                          Emirates
                        </td>
                      </tr>
                      <tr>
                        <td style={{ paddingTop: "16px" }}>
                          <a
                            href="https://supplyme.ae/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "rgb(18, 95, 82)",
                              fontWeight: "bold",
                              fontFamily: "Helvetica, sans-serif",
                              fontSize: "13px",
                              textDecoration: "none",
                            }}
                          >
                            www.supplyme.ae
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h5 className="h5 mb-1 mt-5">Instructions</h5>
          <p>
            Ensure Gmail and other email clients preserve the format of the
            signature.
          </p>
          <ol>
            <li>Use the button below to open signature in a new window</li>
            <li>
              Then select all the content (Win: ctrl + a, Mac: cmd + a) and copy
              it
            </li>
            <li>Then paste in the signature editor of your client</li>
          </ol>
          <button className="btn btn-primary mb-3" onClick={openSignature}>
            Open Signature
          </button>
        </div>
      </Paper>
    </Layout>
  );
};

export default Assets;
